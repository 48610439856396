<template>
  <div class="container">
    <CCol sm="12">
        <CCardBody class="d-flex flex-column">
            <div class="row">
                <center><img src="../../assets/menu/house.png" alt="House" class="house-img"></center>
            </div>
            <div role="group" class="row form-group">
                <!-- Username -->
                <div class="col-lg-12 col-md-12 col-sm-12 mt-5 mb-2">
                    <label class="label-confirmUser">Email</label>
                    <input type="text" v-model="username" class="form-control" :class="{ 'is-invalid': submitted && hasError('Username') }"/>
                    <div v-if="submitted && hasError('Username')" class="invalid-feedback">
                        <span v-if="!$v.username.required">É necessário o Email</span>
                        <span v-if="!$v.username.email">Email Inválido</span>
                        <span v-if="errors.Username">{{errors.Username}}</span>
                    </div>
                </div>
                <!-- Password -->
                <div class="col-lg-12 col-md-12 col-sm-12 mt-5 mb-2">
                    <label class="label-confirmUser">Password</label>
                    <input type="password" v-model="password" class="form-control" :class="{ 'is-invalid': submitted && hasError('Password') }"/>
                    <div v-if="submitted && hasError('Password')" class="invalid-feedback">
                        <span v-if="!$v.password.required">É necessário o Password</span>
                        <span v-if="errors.Password">{{errors.Password}}</span>
                    </div>
                </div>
                <!-- Token -->
                    <div class="col-12 mt-5 mb-2">
                        <label class="label-confirmUser">Token</label>
                        <input type="number" class="form-control" v-model="token" :class="{ 'is-invalid': submitted && hasTokenError }">
                        <div v-if="submitted && hasTokenError" class="invalid-feedback">
                            <span v-if="!$v.token.required">É necessário o Token</span>
                            <span v-if="errors.InvalidToken">{{errors.InvalidToken}}</span>
                        </div>
                    </div>
            </div>
            <div class="row mt-4 justify-content-end align-items-center">
                <a class="col-2 btn btn-secondary resendToken-Btn" @click="showResendTokenPopUp = true">Reenviar Token</a>
                <!-- Action button + loading -->  
                <div class="d-flex justify-content-center col-3">
                    <CSpinner v-if="loading" variant="success" small></CSpinner>
                    <CButton v-else type="button" class="btn align-self-end text-light save-button" @click="confirmAccountClicked">Guardar</CButton>
                </div>
            </div>
        </CCardBody>
    </CCol>
    <ResendTokenPopUp v-if="showResendTokenPopUp" @submit-requested="submitRequested" @closeResendTokenPopUp="showResendTokenPopUp = null; showConfirmationPopup= false" v-model="showResendTokenPopUp"></ResendTokenPopUp>
</div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators'
import { mapState, mapActions } from 'vuex'
import { UTILS_HELPER } from "@/helpers/utils.js";
import ResendTokenPopUp from "@/controls/popups/ResendTokenPopUp.vue";
import EventBus from '@/events/bus.js';

export default {
    components: { ResendTokenPopUp },
data()
    {
        return {
            submitted: false,
                username: '',
                password: '',
                token: '', 
            loading: false,
            showResendTokenPopUp: false
        }
    },
    validations: {
        username: { required, email },
        password: { required },
        token: { required }
    },
    computed:
    {
        ...mapState({ 
        alertType: state => state.alert.type,
        alertOutput: state => state.alert.output
        }),
        ...mapState('account', ['processTask']),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        }
    },
    methods:{
        ...mapActions('account', ['confirmAccount']),
        ...mapActions('account', ['requestToken']),
        validator(val) 
        {
            var result = undefined; // This is the default, in case the user hasn't submitted yet

            if(this.submitted && !val)
            {
                result = false;
            }

            return result;
        },
        confirmAccountClicked: function()
        {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.loading = true;

            const {password, username, token } = this;
            this.confirmAccount({password, username, token});
            
        },
        submitRequested: function(request)
        {
            this.requestToken(request);
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        hasTokenError()
        {
            return this.$v[UTILS_HELPER.convertToCamelCase("Token")].$error || Object.prototype.hasOwnProperty.call(this.errors, "InvalidToken")
        }
    },
    watch:{
       processTask: function(val)
        {
            if(val != null)
            {
                if(val)
                {
                    EventBus.$emit("success-alert-requested", { message: 'Executado com sucesso!', primaryButton: 'Confirmar'});
                }
                this.loading = false;
            }
        },
    }
}
</script>

<style scoped>
.house-img
    {
    padding-top: 2rem;
    margin-bottom: 2rem;
    width: 25vw;
    }
 .label-confirmUser{
        color:rgb(78, 84, 200);
        font-weight: bold;
    }

    .save-button{
        background-color:rgb(78, 84, 200);
        border-radius: 10px;
    }
    .resendToken-Btn{
        display: flex;
        justify-content: center;
    }
    #ownership-selector > span{
        color:gray;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector > span.active{
        color:rgb(143, 148, 251) ;
        font-weight: bold;
        font-size: 30px;
    }

    #ownership-selector.is-invalid > span
    {
        color: #dc3545;
    }
    @media only screen and (max-width: 475px) {
        .resendToken-Btn{
            font-size: 10px;
        }
        .save-button{
            font-size: 10px;
        }
    }
</style>