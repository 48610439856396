<template>
    <BaseModal v-if="showModal" @detailsClosed="$emit('closeResendTokenPopUp')">
        <div class="modal-body-container">
            <h4 class="text-center mt-4">Reenviar Token</h4>  
            <!-- Username -->
            <div class="col-lg-12 col-md-12 col-sm-12 my-2">
                <label class="label-confirmUser">Email</label>
                <input type="text" v-model="username" class="form-control" :class="{ 'is-invalid': submitted && hasError('Username') }"/>
                <div v-if="submitted && hasError('Username')" class="invalid-feedback">
                    <span v-if="!$v.username.required">É necessário o Email</span>
                    <span v-if="!$v.username.email">Email Inválido</span>
                    <span v-if="errors.Username">{{errors.Username}}</span>
                </div>
            </div>
            <CSpinner v-if="loading" variant="success" small class="align-self-end mt-5"></CSpinner>
            <CButton v-else type="button" color="primary" class="mt-4 btn btn-primary align-self-center" @click="requestTokenClicked">Confirmar</CButton>
        </div>
    </BaseModal>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import BaseModal from './BaseModal.vue';
import { required, email } from 'vuelidate/lib/validators';
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
    extends: BaseModal,
    components: { BaseModal },
    validations: {
        username: { required, email },
    },
    data() {
      return {  
        username: '',
        submitted: false,
        loading: false
      }
    },  
    computed:
    {
        ...mapState('account', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output,
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
    },
    methods:
    {
    ...mapActions('account', ['requestToken']),
    requestTokenClicked: function()
    {
        this.submitted = true;

        this.$v.$touch();
        if (this.$v.$invalid) {
            return;
        }  
        this.loading = true;
        const { username } = this;

        this.$emit('submit-requested', this.requestToken({ username }));

    },
     hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
    },
    watch: 
    {
        processTask: function(val)
        {
            if(val)
            {
                this.$emit("closeResendTokenPopUp");
                this.submitted = true;
                this.loading = false;
            }
        }
    }
}
</script>

<style scoped>
    .modal-body-container
    {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        border-radius: 10px;    
        min-height: 200px;
    }
</style>